//import {guardReactiveProps} from "vue";

/*
* WHEN ENABLED IS SET TO 1 = ONLY ROUTER MODE
* WHEN ENABLED IS SET TO 2 = ROUTER + SIDE NAVBAR
*
*
*
* */

const navigation = [

    {name:"home",               title:"Home",                  path:"/",                            props:false, enabled:2,       access:["GUEST","STUDENT","ADMIN",],        },
    {name:"dashboard",          title:"Dashboard",             path:"/dashboard",                   props:false, enabled:2,       access:["STUDENT",],                        },
    {name:"adminDashboard",     title:"Dashboard",             path:"/admin-dashboard",             props:false, enabled:2,       access:["ADMIN",],                          },

    {name:"blog",               title:"Blog",                  path:"/blog",                        props:false, enabled:0,       access:["GUEST","STUDENT",],                },
    {name:"blogEdit",           title:"Blog",                  path:"/blog-edit",                   props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"photo",              title:"Photo Gallery",         path:"/photo",                       props:false, enabled:0,       access:["GUEST","STUDENT",],                },
    {name:"photoEdit",          title:"Photo Gallery",         path:"/photo-edit",                  props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"pricing",            title:"Pricing",               path:"/pricing",                     props:false, enabled:0,       access:["GUEST","STUDENT",],                },
    {name:"pricingEdit",        title:"Pricing",               path:"/pricing-edit",                props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"TnC",                title:"Terms & Conditions",    path:"/terms-and-condition",         props:false, enabled:0,       access:["GUEST","STUDENT",],                },
    {name:"TnCEdit",            title:"Terms & Conditions",    path:"/terms-and-condition-edit",    props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"SocialMedia",        title:"Social Media",          path:"/social-media",                props:false, enabled:0,       access:["GUEST","STUDENT",],                },
    {name:"SocialMediaEdit",    title:"Social Media",          path:"/social-media-edit",           props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"VideoContent",       title:"Video Content",         path:"/video-content",               props:false, enabled:0,       access:["GUEST","STUDENT",],                },
    {name:"VideoContentEdit",   title:"Video Content",         path:"/video-content-edit",          props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"Contact",            title:"Contact",               path:"/contact",                     props:false, enabled:2,       access:["GUEST","STUDENT",],                },
    {name:"ContactEdit",        title:"Contact",               path:"/contact-edit",                props:false, enabled:0,       access:["ADMIN",],                          },

    {name:"MockTest",           title:"Mock Test",             path:"/mock-test",                   props:false, enabled:2,       access:["STUDENT",],                        },

    {name:"MockEdit",           title:"Mock Test",             path:"/mock-test-edit",              props:true, enabled:2,       access:["ADMIN",],
        /*children:[
            {name:"Lister",        path:"",            props:true,},
            {name:"Details",   path:":mockId",         props:true,},
            {name:"Question",  path:":mockId/:questionId",    props:true,},
        ]*/
    },
]

let component = {
    Contact: ()=> import("@/views/common/contact"),
    MockEdit: ()=> import("@/views/admin/mock/Index"),
    /*MockEdit:{
        root: ()=>import("@/views/admin/mock/Index"),
        Lister: ()=>import("@/views/admin/mock/list"),
        Details: ()=>import("@/views/admin/mock/detailsEdit"),
        Question: ()=>import("@/views/admin/mock/questionEdit"),
    },*/


}

let accessFilter = (nav, access)=>{
    let currentAccess = ["GUEST"]
    if(access !== null)
        currentAccess = access

    return nav.filter((el)=>{
        if(el.enabled === false){
            return false
        }else{
            if( el.access.includes("GUEST"))
                return true
            else{
                let granted = false
                currentAccess.forEach(role=>{
                    if(el.access.includes(role))
                        granted = true
                })
                return granted
            }
        }
    })
}

let getComponent = (ref)=>{
    let compRef = {...component}
    ref.split(".").forEach(el=>{
        compRef = compRef[el] ? compRef[el] : ()=>import("@/views/underDevelopement")
    })
    if(typeof compRef === "object")
        compRef = compRef.root
    return compRef
}


export default {
    router : ()=>{
        return navigation.map((el)=>{
            let mainParent =  {
                path:el.path,
                name:el.name,
                enabled: el.enabled > 0,
                component: getComponent(el.name),
                props: el.props ? el.props : false,
            }

            if(el.children){
                mainParent.children = el.children.map((child)=>{
                    return {
                        path: child.path,
                        component: getComponent(el.name + "." + child.name),
                        props: el.props ? el.props : false,
                    }
                })
            }

            return mainParent

        }).filter((el)=>el.enabled)
    },

    sideNavBar : (access = [])=>{
        const nav = navigation.map((el)=>{
            return {
                name:el.name,
                title:el.title,
                enabled:el.enabled === 2,
                href:{name: el.name},
                access:el.access,
            }
        })
        return accessFilter(nav , access)
    }
}