<template>
    <div class="left-sidenav">
        <logo/>
        <sidebar/>
    </div>
</template>

<script>
import Logo from "@/components/UI/Theme/SideNavBar/components/logo";
import Sidebar from "@/components/UI/Theme/SideNavBar/components/sidebar";
export default {
    name: "Index",
    components: {Sidebar, Logo}
}
</script>

<style scoped>

</style>