let enlargeMenu = window.innerWidth < 1025
toggleBodyClass()
window.onresize = ()=>{
    enlargeMenu = window.innerWidth < 1025
    toggleBodyClass()
}

const Theme = {
    toggleMenu:()=>{
        enlargeMenu = !enlargeMenu
        toggleBodyClass()
    },
    getEnlargeMenu:()=>{
        return enlargeMenu
    },
    menuLinkClick:()=>{
        if(window.innerWidth < 1025)
            Theme.toggleMenu()
    }
}


function toggleBodyClass(){
    if(enlargeMenu)
        document.getElementsByTagName("body")[0].classList.add("enlarge-menu")
    else{
        document.getElementsByTagName("body")[0].classList.remove("enlarge-menu")
    }
}


export default (app)=>{
    app.config.globalProperties.$theme = Theme
}