import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import cookie from './plugins/cookie'

import user from "@/service/user";
import cmd from "@/service/cmd";
import theme from "@/service/theme";

import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap.css"

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css"

import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all"

import axios from "axios";
import VueAxios from "vue-axios";

import "@/assets/style.css"

const app = createApp(App)

    .use(cookie)
    .use(router)
    .use(VueAxios, axios)
    .use(store)
    .use(VueSweetalert2)

    .use(user)
    .use(cmd)
    .use(theme)


app.mount('#app')
