<template>
    <li class="dropdown">
        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="#" role="button"
           aria-haspopup="false" aria-expanded="false">
            <img :src="getPhoto" alt="profile-user" class="rounded-circle thumb-xs" />
        </a>
        <div class="dropdown-menu dropdown-menu-right" v-if="this.$user.getUserDetails('name')">
            <a class="dropdown-item" href="#"><i data-feather="user" class="align-self-center icon-xs icon-dual mr-1"></i> Profile</a>
            <!--a class="dropdown-item" href="#"><i data-feather="settings" class="align-self-center icon-xs icon-dual mr-1"></i> Settings</a-->
            <div class="dropdown-divider mb-0"></div>
            <a href="#" class="dropdown-item" @click="logout"><i data-feather="power" class="align-self-center icon-xs icon-dual mr-1"></i> Logout</a>
        </div>
        <div class="dropdown-menu dropdown-menu-right" v-else>
            <router-link :to="{name:'login'}" class="dropdown-item"><i data-feather="user" class="align-self-center icon-xs icon-dual mr-1"></i> Login</router-link>
            <router-link :to="{name:'register'}" class="dropdown-item"><i data-feather="user" class="align-self-center icon-xs icon-dual mr-1"></i> Register</router-link>
            <router-link :to="{name:'forget'}" class="dropdown-item"><i data-feather="user" class="align-self-center icon-xs icon-dual mr-1"></i> Forget</router-link>
        </div>
    </li>
</template>

<script>
export default {
    name: "profile",
    computed:{
        getPhoto(){
            const photo = this.$user.getUserDetails("photo")
            if(photo)
                return photo
            else
                return this.$cmd.url('img/logo.png')
        }
    },
    methods:{
        logout(){
            if(this.$user.logout()){
                window.location = "/"
            }
        }
    }
}
</script>

<style scoped>

</style>