import { createRouter, createWebHistory } from 'vue-router'
import navigation from "@/router/navigation"

const routes = [
    ...navigation.router(),
  {path: '/register',           name: 'register',          component: () => import('../views/Register')},
  {path: '/login',              name: 'login',          component: () => import('../views/Login')},
  {path: '/forget-password',    name: 'forget',          component: () => import('../views/Forget')},
  {path: '/:pathMatch(.*)*',    name: '404',          component: () => import('../views/404')},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//need to add navigation guard

export default router











