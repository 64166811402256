import axios from "axios";
const cookie = require("tiny-cookie")

const ResourceServer = ()=>{

    let headers = {
        "Content-Type":"application/json"
    }

    let token = cookie.get("token")
    if(token)
        headers["Auth"] = token;

    const generateUrl=(url)=>{
        return `${process.env.VUE_APP_API_HOST}${url}`
    }

    const postFormData = (data={})=>{
        let form = new FormData();
        Object.keys(data).forEach((key)=>{
            form.append(key,data[key])
        })
        return form
    }

    return {
        post:(url, data={})=>{
            return axios.post(""+generateUrl(url), postFormData(data), {headers})
        },
        get:(url)=>{
            return axios.get(""+generateUrl(url), {headers})
        }
    }
}


export default ResourceServer