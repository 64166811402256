<template>
    <div class="menu-content h-100" data-simplebar>
        <ul class="metismenu left-sidenav-menu">
            <template v-for="(item,index) in menu" :key="index">
                <li>
                    <router-link :to="item.href" @click="this.$theme.menuLinkClick()">
                        <h6>{{item.title}}</h6>
                    </router-link>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import navigation from "@/router/navigation";
export default {
    name: "sidebar",
    data(){
        return{
            menu:navigation.sideNavBar(this.$user.getUserDetails("role"))
        }
    },
}
</script>

<style scoped>

</style>