<template>
    <div class="brand">
        <router-link to="/" class="logo">
            <span v-if="this.$theme.getEnlargeMenu()">
                <img :src="this.$cmd.url('img/logo.png')" alt="logo-small" class="logo-sm">
            </span>
            <span v-else>
                <img :src="this.$cmd.url('img/logo-lg.png')" alt="logo-large" class="logo-lg logo-light">
                <img :src="this.$cmd.url('img/logo-lg.png')" alt="logo-large" class="logo-lg logo-dark">
            </span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "logo"
}
</script>

<style scoped>

</style>