<template>
   <side-nav-bar/>
    <div class="page-wrapper">
        <top-nav-bar/>
        <!-- Page Content-->
        <div class="page-content">
            <div class="container-fluid">
                <router-view></router-view>
            </div><!-- container -->

            <footer class="footer text-center text-sm-left">
                &copy; 2020 Bilaspur Law Academy<span class="d-none d-sm-inline-block float-right">All Rights Reserved</span>
            </footer><!--end footer-->
        </div>
        <!-- end page content -->
    </div>
    <!-- end page-wrapper -->
</template>

<script>
import sideNavBar from "@/components/UI/Theme/SideNavBar/Index"
import topNavBar from "@/components/UI/Theme/TopBar/Index"
export default {
    components:{sideNavBar, topNavBar}
}
</script>
