const Cmd = {
    url:(to)=>{
        return `/${to}`
    },


    formatDate(date){
        let dt= date.split(" ")[0]
        let time= date.split(" ")[1]

        dt = dt.match(/^(\d{4})-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/)
        console.log(dt,time)

        return{
            date:{
                dmy: `${dt[3]}/${dt[2]}/${dt[1]}`,
                ymd: dt[0],
            }
        }
    }
}

export default (app)=>{
    app.config.globalProperties.$cmd = Cmd
}