<template>
    <div class="topbar">
        <nav class="navbar-custom">
            <ul class="list-unstyled topbar-nav float-right mb-0">
                <search-bar v-if="false" />
                <notification v-if="false"/>
                <profile />
            </ul>
            <ul class="list-unstyled topbar-nav mb-0">
                <li>
                    <button class="nav-link button-menu-mobile" @click="this.$theme.toggleMenu()">
                        <i class="align-self-center topbar-icon fas fa-bars"></i>
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import SearchBar from "@/components/UI/Theme/TopBar/searchBar";
import Notification from "@/components/UI/Theme/TopBar/notification";
import Profile from "@/components/UI/Theme/TopBar/profile";
export default {
    name: "Index",
    components: {Profile, Notification, SearchBar}
}
</script>

<style scoped>

</style>