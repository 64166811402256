const cookie = require("tiny-cookie")
import ResourceServer from "@/service/api/ResourceServer";
const User = {
    getUserDetails:(key = null)=>{

        let token = cookie.get("user") // atob()
        if(token !== null && token !== "null"){
            const cookieValue = JSON.parse( atob(token) )
            if(cookieValue && key)
                if(cookieValue[key])
                    return cookieValue[key]
                else
                    return null
            else
                return  cookieValue
        }
        return null

    },





    registerUser:(name, email, mobile, password)=>{
        return ResourceServer().post("user/register",{
            name:name, email:email, mobile:mobile, password:password,
        })
    },

    login(uid,password){
        return ResourceServer().post("user/login",{
            uid: uid,
            password: password,
        })
    },
    logout(){
        cookie.remove("user")
        return true
    },
    forgetPassword:(uid)=>{
        return ResourceServer().post("user/forget",{
            uid:uid,
        })
    },
    setToken:(token)=>{
        cookie.set("user",token)
    }







}


export default (app)=>{
    app.config.globalProperties.$user = User
}